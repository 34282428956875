/* @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Poppins:wght@400;500;700;800&display=swap'); */
/* 
@font-face {
  font-family: "Roboto", sans-serif !important;
} */
body {
  margin: 0;
  background-color: #f3f3f3;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

html * {
  font-family: "Roboto", sans-serif !important;
}
